import React, { Component } from "react";
import { EasingFunctions } from "./easing";
import { runAnimations } from "./animations";

import "./section-container.scss";

// SectionContainer: scrolling
// O objetivo é implementar o scroll-snap sem o uso do CSS (pois não é suportado
// por todos os navegadores ainda e é inflexível em alguns pontos).
// O componente irá escutar o evento onScroll do container e executar o scroll
// automático alguns ms após o usuário parar de fazer scroll.

const SCROLL_AFTER_MSECS = 0;
const TOUCH_START = "touchstart";
const TOUCH_END = "touchend";

let userAgent = window.navigator.userAgent;
const isSafariIos = /(iPad|iPhone)/i.test(userAgent);
const scrollingElement = () => {
    if (isSafariIos)
        return document.scrollingElement;
    return document.getElementById("root");
}
const scrollingElements = () => {
    if (isSafariIos) {
        return {
            doc: document,
            win: window,
        };
    }

    let el = scrollingElement();
    return {
        doc: el,
        win: el,
    };
}

const setHtmlClass = (className, set = true) => {
    let html = document.querySelector("html");
    let hasIt = html.classList.contains(className);

    if (set && !hasIt) {
        html.classList.add(className);
    }
    if (!set && hasIt) {
        html.classList.remove(className);
    }
};

class SectionContainer extends Component {
    constructor(props) {
        super(props);

        this.container = React.createRef();
        this.onScroll = this.onScroll.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.doAutoScroll = this.doAutoScroll.bind(this);
        this.observerCallback = this.observerCallback.bind(this);
        this.handleTouchEvent = this.handleTouchEvent.bind(this);

        // 2020-03-06: Ativando o scroll após a montagem do componente
        this._suspendScrolling = true;
        this._lastScrollPos = 0;
        this._visibleSections = [0];
    }

    componentDidMount() {
        window._scrollTo = this.animateScrollTo.bind(this);
        this.onScroll(null);

        window.setTimeout(
            () => this._suspendScrolling = false,
            500
        );

        let scrollEls = scrollingElements();
        if (!isSafariIos) {
            setHtmlClass("no-safari");
        }

        document.addEventListener("keydown", this.onKeyDown);

        scrollEls.doc.addEventListener(TOUCH_START, this.handleTouchEvent);
        scrollEls.doc.addEventListener(TOUCH_END, this.handleTouchEvent);

        let container = this.container.current;
        if (!container || !IntersectionObserver) return;

        scrollEls.win.addEventListener("scroll", this.onScroll);
        scrollEls.doc.addEventListener("wheel", this.onWheel);

        let sections = container.querySelectorAll(".page-section");
        let options = {
            rootMargin: "0px",
            threshold: [0.01, 0.75],
        };

        this.observer = new IntersectionObserver(
            this.observerCallback, options);

        sections.forEach((section, index) => {
            section.dataset.sectionIdx = index;

            const sectionAnimations = section.querySelectorAll("[data-animated]");

            sectionAnimations.forEach((el, index) => {
                el.style.setProperty("--delay", `${index * 250}ms`);
            });

            this.observer.observe(section);
        });
    }

    componentWillUnmount() {
        window._scrollTo = null;

        if (this.observer) {
            this.observer.disconnect();
            this.observer = null;
        }

        let scrollEls = scrollingElements();
        scrollEls.win.removeEventListener("scroll", this.onScroll);
        scrollEls.doc.removeEventListener("wheel", this.onWheel);
        scrollEls.doc.removeEventListener(TOUCH_START, this.handleTouchEvent);
        scrollEls.doc.removeEventListener(TOUCH_END, this.handleTouchEvent);

        document.removeEventListener("keydown", this.onKeyDown);

        if (this._doScrollTimer) {
            window.clearTimeout(this._doScrollTimer);
            this._doScrollTimer = null;
        }
    }

    onKeyDown(e) {
        if (this._suspendScrolling) return;

        let el = scrollingElement();
        let capture = true;

        let container = this.container.current;
        let sections = container.querySelectorAll(".page-section");
        let nextSectionIdx = -1, prevSectionIdx = -1;
        for (let k = 0; k < sections.length; k++) {
            let offset = sections[k].offsetTop;

            if (offset < el.scrollTop)
                prevSectionIdx = k;

            if (offset > el.scrollTop) {
                nextSectionIdx = k;
                break;
            }
        }

        switch(e.key) {
            case "Home":
                if (el.scrollTop > 0)
                    this.animateScrollTo(0);
                break;
            case "End":
                this.animateScrollTo(
                    el.scrollHeight
                );
                break;

            case "ArrowUp":
            case "PageUp":
                if (prevSectionIdx >= 0) {
                    this.animateScrollTo(sections[prevSectionIdx].offsetTop);
                }
                break;

            case "ArrowDown":
            case "PageDown":
                if (nextSectionIdx >= 0) {
                    this.animateScrollTo(sections[nextSectionIdx].offsetTop);
                }
                else {
                    capture = false;
                }
                break;

            default:
                capture = false;
        }

        if (capture) e.preventDefault();
    }

    animateScrollTo(py) {
        if (this._suspendScrolling) return;
        this._suspendScrolling = true;

        let el = scrollingElement();
        easeScroll(el, py, () => {
            this._suspendScrolling = false;
        });
    }

    handleTouchEvent(e) {
        let el = scrollingElement();

        switch(e.type) {
            case TOUCH_START:
                this._suspendScrolling = true;
                this._lastScrollPos = el.scrollTop;
                break;

            case TOUCH_END:
                this._suspendScrolling = false;
                this.onScroll(e);
                break;

            default:
        }
    }

    onScroll(e) {
        let el = scrollingElement();
        setHtmlClass("top-of-page", el.scrollTop <= 10);

        if (this._suspendScrolling) {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            return;
        }

        this._lastOnScroll = new Date();

        if (!this._doScrollTimer) {
            this._doScrollTimer = window.setTimeout(
                this.doAutoScroll, SCROLL_AFTER_MSECS);
        }

        let newScrollPos = el.scrollTop;

        this._scrollDirection = newScrollPos > this._lastScrollPos ? 1 : -1;
        this._lastScrollPos = newScrollPos;
    }
    onWheel(e) {
        if (this._suspendScrolling) {
            e.preventDefault();
        }
    }

    doAutoScroll() {
        let now = new Date();
        let delta = now - this._lastOnScroll;

        if (delta >= SCROLL_AFTER_MSECS) {
            this.executeAutoScroll();
            this._doScrollTimer = null;
        }
        else {
            let remainingMsecs = SCROLL_AFTER_MSECS - delta + 1;
            this._doScrollTimer = window.setTimeout(
                this.doAutoScroll, remainingMsecs);
        }
    }

    executeAutoScroll() {
        if (this._visibleSections.length < 2) return;

        // Dependendo do _scrollDirection, user a seção com o maior / menor idx
        let direction = this._scrollDirection;
        let dstSectionIdx = direction > 0 ? 0 : 1000;
        this._visibleSections.forEach(idx => {
            if (direction > 0) {
                if (idx > dstSectionIdx) dstSectionIdx = idx;
            }
            else {
                if (idx < dstSectionIdx) dstSectionIdx = idx;
            }
        });

        let container = this.container.current;
        let sections = container.querySelectorAll(".page-section");
        let dstSection = null;
        sections.forEach(section => {
            if (parseInt(section.dataset.sectionIdx, 10) === dstSectionIdx)
                dstSection = section;
        });

        if (!dstSection) return;

        // Se o scroll já tiver passado do ponto desejado, não fazer nada
        let targetScroll = dstSection.offsetTop;
        let element = scrollingElement();

        if (direction < 0 && element.scrollTop <= targetScroll)
            return;
        if (direction > 0 && element.scrollTop >= targetScroll)
            return;

        this.animateScrollTo(dstSection.offsetTop);
    }

    observerCallback(entries, observer) {
        entries.forEach(entry => {
            const { target } = entry;
            let ratio = entry.intersectionRatio;
            
            if (ratio >= 0.75) {
                target.classList.add("is-visible");

                window.setTimeout(
                    () => this.triggerAnimations(target),
                    0
                );
            }

            let sectionIdx = parseInt(target.dataset.sectionIdx);
            let entryIdx = this._visibleSections.indexOf(sectionIdx);

            if (ratio < 0.01) {
                target.classList.remove("is-visible");

                if (entryIdx >= 0)
                    this._visibleSections.splice(entryIdx, 1);
            }
            else {
                if (entryIdx < 0)
                    this._visibleSections.push(sectionIdx);
            }

        });
    }

    triggerAnimations(el) {
        let animations = el.querySelectorAll("[data-animation-id]");
        let aniIds = [];
        animations.forEach(ani => {
            let id = ani.dataset.animationId;
            aniIds.push(id);
        });

        runAnimations(aniIds);
    }

    render() {
        return (
            <div className="section-container" ref={ this.container }>
                { this.props.children }
            </div>
        );
    }
}

const SCROLL_EFFECT_DURATION = 800;
const easeScroll = (el, targetPos, cb = null) => {
    let startPos = el.scrollTop;
    let delta = targetPos - startPos;
    let startTime = null;

    const step = (ms) => {
        if (!startTime) startTime = ms;

        let p = (ms - startTime) / SCROLL_EFFECT_DURATION;
        if (p >= 1) {
            el.scrollTop = targetPos;
            if (cb) cb();
            return;
        }

        if (p > 0) {
            let newPos = EasingFunctions.easeInOutCubic(p) * delta + startPos;

            let updateScroll = true;
            if (delta < 0 && el.scrollTop <= newPos) updateScroll = false;
            if (delta > 0 && el.scrollTop >= newPos) updateScroll = false;

            if (updateScroll) {
                el.scrollTop = newPos;
            }
        }
        window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);

};

export {
    SectionContainer,
};