import React from "react";

const Icon = props => {
    let iconStyle = {
        backgroundImage: `url(${ props.icon })`,
    };

    return <div className="icon" style={ iconStyle } />;
};

export { Icon };
