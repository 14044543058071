const registerEvent = (eventName, handler) => {
    eventName = eventName.toLowerCase();
    window.addEventListener(eventName, handler);
};

const unregisterEvent = (eventName, handler) => {
    eventName = eventName.toLowerCase();
    window.removeEventListener(eventName, handler);
}

const raiseEvent = (eventName, detail = null) => {
    eventName = eventName.toLowerCase();
    let event = null;
    if (typeof(CustomEvent) !== "undefined")
        event = new CustomEvent(eventName, { detail });
    else {
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
        event.detail = detail;
    }

    window.dispatchEvent(event);
}

export {
    registerEvent,
    unregisterEvent,
    raiseEvent,
};
