import React, { Component } from "react";
import { EasingFunctions } from "./easing";
import axios from "axios";
import { ReactComponent as SetaSvg } from "./seta.svg";

import "./carousel.scss";

const FONT_DATA = ["/fonts/CoreSansAR-15Thin.woff"];

class Carousel extends Component {
    constructor(props) {
        super(props);

        let imagesToBeLoaded = 0;
        const imageLoaded = () => {
            if (--imagesToBeLoaded === 0) {
                this.renderFrame();
            }
        };

        console.log("car", props.items);

        FONT_DATA.forEach(font => {
            axios.get(font).then(imageLoaded);
            imagesToBeLoaded++;
        });
        this.images = props.items.map(entry => {
            let img = new Image();
            let imgSrc = `/api/images/${ entry.img_path }?d=500x375&m=2&q=92`;
            img.src = imgSrc;
            img.dataset.title = entry.title;
            img.dataset.description = entry.text;

            imagesToBeLoaded++;
            img.onload = imageLoaded;
            return img;
        });


        let startingIdx = 0;
        this.state = {
            activeBullet: startingIdx,
            activeText: startingIdx,
        };

        this.canvas = React.createRef();
        this.active = startingIdx;
        this.scaledImages = [];
        this.scaledImages[startingIdx] = 1;

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this._tmr = window.setInterval(
            () => this.renderFrame(),
            3000
        );
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        if (this._tmr) {
            window.clearInterval(this._tmr);
            this._tmr = null;
        }
    }

    resize() {
        if (this._refreshToken) return;

        this.resized = false;
        this._refreshToken = window.requestAnimationFrame(
            () => {
                this._refreshToken = null;
                this.renderFrame();
            }
        );
    }

    resizeCanvas(el, canvas) {
        let cs = window.getComputedStyle(el);
        let w = parseInt(cs.getPropertyValue("width"), 10);
        let h = parseInt(cs.getPropertyValue("height"), 10);

        let r = window.devicePixelRatio || 1;
        canvas.width = w * r;
        canvas.height = h * r;

        canvas.style.width = `${ w }px`;
        canvas.style.height = `${ h }px`;
    }

    renderFrame() {
        let el = this.canvas.current;
        let canvas = el.querySelector("canvas");

        if (!this.resized) {
            this.resizeCanvas(el, canvas);
            this.resized = true;
        }

        let ctx = canvas.getContext('2d');

        let twidth = canvas.width / window.devicePixelRatio;
        let theight = (canvas.height / window.devicePixelRatio);

        // clear frame
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

        ctx.clearRect(0, 0, twidth, theight);
        theight -= 26;

        // draw images
        let images = this.images;
        let imgWidth = Math.floor(twidth / 3);
        let imgHeight = Math.floor((theight / 4) * 3.2);
        let imgRatio = imgWidth / imgHeight;

        ctx.imageSmoothingEnabled = true;
        ctx.textAlign = "center";
        //ctx.fillStyle = "#000000";

        let prevIdx = this.active - 2;
        let nextIdx = this.active + 2;

        let imgPy = theight - imgHeight;
        ctx.translate(0, imgPy);

        let nearestIdx = Math.round(this.clampImageIdx(this.active));
        if (nearestIdx !== this.state.activeBullet) {
            this.setState({
                activeBullet: nearestIdx,
            });
        }

        const drawImage = (img, idx, scale = 0) => {
            // Para estar visível, o índice desta imagem precisa estar a até
            // 1 unidade de distância do active
            let distanceToActive = idx - this.active;
            let visible = -2 < distanceToActive && distanceToActive < 2;
            if (!visible) {
                if (prevIdx < 0) {
                    distanceToActive = idx - (this.active + images.length);
                }
                if (nextIdx > images.length - 1) {
                    distanceToActive = (idx + images.length) - this.active;
                }
                visible = -2 < distanceToActive && distanceToActive < 2;
            }

            if (!visible) return;

            let ratio = img.width / img.height;
            let sx = 0, sy = 0, sw = img.width, sh = img.height;
            if (ratio > imgRatio) {
                // A imagem atual é mais larga, o corte será no X
                sw = sh * imgRatio;
                sx = (img.width - sw) / 2;
            }
            else {
                sh = sw / imgRatio;
                sy = (img.height - sh) / 2;
            }

            let dx = (distanceToActive + 1) * imgWidth, dy = 0;
            let dw = imgWidth, dh = imgHeight;

            if (scale > 0) {
                dh += Math.floor((theight - dh) * scale);
                dy = imgHeight - dh;

                let p = dh / imgHeight;
                dw = Math.floor(dw * p);
                dx -= Math.floor((dw - imgWidth) / 2);

                //ctx.shadowColor = '#000';
                //ctx.shadowBlur = 10;
            }

            let isActive = nearestIdx === idx;
            ctx.globalAlpha = isActive || twidth >= 800 ? 1 :
                Math.max(0.3, 1 - Math.abs(distanceToActive) * .7);

            ctx.drawImage(
                img,
                sx, sy, sw, sh,
                dx, dy, dw, dh);

            //ctx.shadowColor = null;

            // Text
            if (!isActive && twidth < 450) return;

            let fontSize = 16 + Math.min(4, 4 * scale);
            //ctx.font = `100 ${ fontSize }px MyriadPro`;
            //ctx.font = `100 ${ fontSize }px Source Sans Pro`;
            ctx.font = `200 ${ fontSize }px CoreSansAR`;
            ctx.fillText(img.dataset.title, dx + dw / 2, imgHeight + 22);
        };

        let drawLater = [];
        images.forEach((img, idx) => {
            let scale = this.scaledImages[idx];
            if (scale > 0) {
                drawLater.push({
                    img,
                    idx,
                    scale
                });
            }
            else
                drawImage(img, idx);
        });

        drawLater.forEach(e =>
            drawImage(e.img, e.idx, e.scale));

    }

    render() {
        let active = this.state.activeBullet;
        let entry = this.images[this.state.activeText];
        let description = entry ? entry.dataset.description : "";

        return (
        <div className="carousel">
            <div className="canvas" ref={ this.canvas }>
                <div className="actions prev" onClick={ e => this.move(-1) }>
                    <SetaSvg />
                </div>
                <div className="actions next" onClick={ e => this.move(1) }>
                    <SetaSvg />
                </div>

                <canvas />
            </div>

            <div className="description">
                {description}
            </div>

            <div className="bullets">
                { this.images.map((img, idx) =>
                    <div key={ `b${ idx }` }
                        className={ `bullet ${ idx === active ? "active" : "" }` }
                        onClick={ e => this.goto(idx) } />
                )}
            </div>

        </div>
        );
    }

    move(dir) {
        let imgCount = this.images.length;
        let newActive = Math.round((this.active + dir) % imgCount);
        while (newActive < 0)
            newActive += imgCount;

        this.animateGoto(this.active, newActive, dir);
    }
    goto(idx) {
        this.animateGoto(this.active, idx);
    }

    clampImageIdx(idx) {
        let imgCount = this.images.length;

        while (idx < 0) idx += imgCount;
        return idx % imgCount;
    }

    animateGoto(oldIdx, newIdx, dir = 0) {
        let delta;
        let imgCount = this.images.length;

        switch (dir) {
            case 1:
                while (newIdx < oldIdx)
                    newIdx += imgCount;
                delta = newIdx - oldIdx;
                break;
            case -1:
                while (newIdx > oldIdx)
                    oldIdx += imgCount;
                delta = newIdx - oldIdx;
                break;
            default:
                delta = newIdx - oldIdx;
        }

        let srcIdx = this.clampImageIdx(oldIdx);
        let dstIdx = this.clampImageIdx(newIdx);

        if (srcIdx === dstIdx) return;
        if (this.animating) return;
        this.animating = true;

        const animDuration = 600;

        let scaleArr = this.scaledImages = [];
        scaleArr[srcIdx] = 1;
        scaleArr[dstIdx] = 0;

        let start = null;
        const step = (ms) => {
            if (start == null) start = ms;
            let pt = (ms - start) / animDuration;
            let p = EasingFunctions.easeInOutCubic(pt);

            if (p >= 1) {
                scaleArr[srcIdx] = 0;
                scaleArr[dstIdx] = 1;
                this.active = dstIdx;

                this.renderFrame();
                this.animating = false;

                this.setState({
                    activeBullet: dstIdx,
                    activeText: dstIdx,
                });
                return;
            }

            scaleArr[srcIdx] = p <= .6 ?
                1 - EasingFunctions.linear(p / .6) : 0;
            scaleArr[dstIdx] = p >= .4 ?
                EasingFunctions.linear((p - .4) / .6) : 0;

            this.active = oldIdx + delta * p;
            this.renderFrame();
            window.requestAnimationFrame(step);
        }

        window.requestAnimationFrame(step);
    }
}

export {
    Carousel,
};
