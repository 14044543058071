/* Registro de animações */
const getAnimations = () => {
    let animations = window.__animations;
    if (!animations) {
        animations = {};
        window.__animations = animations;
    }

    return animations;
}

const registerAnimation = (id, comp) => {
    let animations = getAnimations();
    animations[id] = comp;
};

const unregisterAnimation = id => {
    let animations = getAnimations();
    delete animations[id];
};

const runAnimations = idList => {
    let animations = getAnimations();

    idList.forEach(id => {
        let comp = animations[id];
        if (comp) comp.animate();
    });
};

export {
    registerAnimation,
    unregisterAnimation,
    runAnimations,
}
