import React, { Suspense, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useLocation,
} from "react-router-dom";

import { raiseEvent } from "./components/util";

import { AppHeader } from "./components/app-header";
import { AppFooter } from "./components/app-footer";
import { HomePage } from "./pages/home";

import "./App.scss";

// Lazy Loading
const LazyLoad = (props) =>
    <Suspense fallback={ <div className="loading">Carregando...</div> }>
        { props.children }
    </Suspense>;

const FaleConosco = React.lazy(() => import("./pages/fale-conosco"));

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (window._scrollTo)
            window._scrollTo(0);
        else {
            let root = document.querySelector("#root");
            if (root) {
                root.scrollTop = 0;
            }
            else
                window.scrollTo(0, 0);
        }
    }, [pathname]);

    raiseEvent("onreactroutechanged");

    return null;
};

function App() {
    return (
        <Router>
            <ScrollToTop />
            <AppHeader />
            <Switch>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route path="/fale-conosco">
                    <LazyLoad>
                        <FaleConosco />
                    </LazyLoad>
                </Route>

                <Redirect to="/" />
            </Switch>
            <AppFooter />
        </Router>
    );
}

export default App;
