import React, { Component } from "react";
import { Link } from "react-router-dom";

import { PageSection } from "../components/page-section";
import { SectionContainer } from "../components/section-container";
import { AnimatedCounter } from "../components/animated-counter";
import { Carousel } from "../components/carousel";

import { Icon } from "../components/icon";

import { HeroSimple, HeroContent } from "../components/hero-simple";
import Axios from "axios";

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        document.querySelector("html").classList.add("home-page");

        Axios.get("/api/home").then(res => {
            let d = res.data;
            if (d.status === "OK") {
                let blocks = {};
                d.blocks.forEach(block => {
                    blocks[block.id] = block;
                });

                this.setState({
                    loading: false,
                    blocks,
                });
            }
        });
    }
    componentWillUnmount() {
        document.querySelector("html").classList.remove("home-page");
    }

    render() {
        if (this.state.loading) return null;

        let nobg = document.location.hash.includes("nobg");
        let blocks = this.state.blocks;

        let bgHero1 = "/foto-fundo-new.jpg";
        if (blocks.hero1 && blocks.hero1.logo_path) {
            bgHero1 = `/api/images/${ blocks.hero1.logo_path }?d=980x612&m=2&q=90`;
        }

        return (
            <SectionContainer>
                <PageSection
                    background-color="#cfd8dc"
                    background={ bgHero1 }
                    >
                        <HeroSimple classes="avenir" { ...blocks.hero1 }/>
                    </PageSection>
                <PageSection>
                    <HeroSimple
                        classes="hero2"
                        { ...blocks.hero2 }>

                        { nobg ? null :
                        <img className="bg-hero"
                            src="/background-hero.svg" alt="TV TEM" />
                        }
                    </HeroSimple>
                </PageSection>
                <PageSection id="regioes-tvtem">
                    <HeroSimple content-mode
                        classes="hero3"
                        { ...blocks.hero3 }>
                        <div className="spacer grow"></div>
                        <HeroContent />
                        <div className="counters" data-animated>
                            { blocks.hero3.counters.map((counter, idx) =>
                                <AnimatedCounter
                                    key={ `ani${ idx }`}
                                    { ...counter } />
                            )}
                        </div>
                        <div className="link-container grow">
                            <Link to="/fale-conosco" data-animated
                                className="link">
                                Conheça as oportunidades
                            </Link>
                        </div>
                    </HeroSimple>
                </PageSection>
                <PageSection id="all-line">
                    <HeroSimple content-mode
                        classes="hero3"
                        { ...blocks.hero4 }>
                        <div className="spacer grow"></div>
                        <HeroContent />
                        <div className="grid-comm" data-animated>

                            { blocks.hero4.items.map((item, idx) =>
                                <GridItem key={ `item${ idx }`} { ...item } />
                            )}

                        </div>
                        <div className="link-container grow">
                            <Link to="/fale-conosco" data-animated
                                className="link orange">
                                Quero para minha empresa
                            </Link>
                        </div>
                    </HeroSimple>
                </PageSection>
                <PageSection id="oportunidades">
                    <HeroSimple content-mode
                        classes="hero3"
                        { ...blocks.hero5 }>
                        <div className="spacer grow"></div>
                        <HeroContent />
                        <Carousel items={ blocks.hero5.items } />
                        <div className="link-container grow">
                            <Link to="/fale-conosco" data-animated
                                className="link blue">
                                Quero para minha empresa
                            </Link>
                        </div>
                    </HeroSimple>
                </PageSection>

            </SectionContainer>
        );
    }
};

const GridItem = props =>
    <div>
        <Icon icon={ props.icon } />
        <span>{ props.text }</span>
    </div>;

export {
    HomePage,
};
