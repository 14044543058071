import React, { Component } from "react";
import { Icon } from "./icon";
import { EasingFunctions } from "./easing";
import { registerAnimation, unregisterAnimation } from "./animations";

import "./animated-counter.scss";

const ANIMATION_DURATION = 2500;

class AnimatedCounter extends Component {
    constructor(props) {
        super(props);

        this.counter = React.createRef();
        this.animation_id = Math.floor(Math.random() * 10000000).toString();
    }

    componentDidMount() {
        registerAnimation(this.animation_id, this);
        //this.animate();
    }

    componentWillUnmount() {
        unregisterAnimation(this.animation_id);
    }

    animate() {
        let startedAt = null;

        if (this._aniFrame) {
            window.cancelAnimationFrame(this._aniFrame);
            this._aniFrame = null;
        }

        const step = (ms) => {
            if (!this.counter.current) return;

            if (startedAt == null) {
                startedAt = ms;
                this._value = this.formatNumber(0);
                this.counter.current.innerText = this._value;
            }
            else {
                let t = (ms - startedAt) / ANIMATION_DURATION;
                if (t >= 1) {
                    t = 1;
                }
                else {
                    t = EasingFunctions.easeInOutCubic(t);
                }

                let v = this.props.value * t;
                let nv = this.formatNumber(v);
                if (nv !== this._value) {
                    this._value = nv;
                    this.counter.current.innerText = this._value;
                }

                if (t >= 1) {
                    this._aniFrame = null;
                    return;
                }
            }

            this._aniFrame = window.requestAnimationFrame(step);
        };

        this._aniFrame = window.requestAnimationFrame(step);
    }

    formatNumber(n, prefix = "+") {
        let decimals = this.props.decimals || 0;
        let out = [];

        if (prefix) out.push(prefix);

        for (let k = 0; k <= decimals; k++) {
            let v = Math.floor(n);
            n = (n * 10) % 10;

            out.push(v);
            if (k === 0 && decimals > 0) out.push(",");
        }

        return out.join("");
    }

    render() {
        let props = this.props;

        return (
            <div className="animated-counter"
                data-animation-id={ this.animation_id }>
                <Icon icon={ this.props.icon } />
                <b>{ props.title }</b>
                <u ref={ this.counter }>{ this._value || " " }</u>
                <s>{ props.unit }</s>
                <i>{ props.description }</i>
            </div>
        );
    }
}

export {
    AnimatedCounter,
};
