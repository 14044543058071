import React, { Component } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as LogoFacebook } from "../logo-facebook.svg";
import { ReactComponent as LogoInstagram } from "../logo-instagram.svg";

const ADDRESS_DATA = [
    {
        title: "SOROCABA/SP",
        address: `Av. Engenheiro Carlos R. Mendes, 2700
Alto da Boa Vista | 18013-280
Tel.: 15 3224.8888
marketing.sor@tvtem.com`,
    },
    {
        title: "BAURU/SP",
        address: `Rua Padre Anchieta, 9-41
Bela Vista | 17060-400
Tel.: 14 3233.1000
marketing.bau@tvtem.com`,
    },
    {
        title: "São José do Rio Preto/SP",
        address: `Av. Jornalista Roberto Marinho, 997
Jardim Yolanda | 15061-500
Tel.: 17 3221.1000
marketing.sjr@tvtem.com`,
    },
    {
        title: "Itapetininga/SP",
        address: `Rua Dr. Coutinho, 733 | Loja 1
Centro | 18200-358
Tel.: 15 3376.9000
marketing.itp@tvtem.com`,
    },
];

class AppFooter extends Component {
    constructor(props) {
        super(props);

        this.logoContainer = React.createRef();
    }

    componentDidMount() {
        let logo = document.querySelector(".AppHeader .logo svg");
        if (logo) {
            let newLogo = logo.cloneNode(true);
            let container = this.logoContainer.current;

            container.appendChild(newLogo);
        }
    }

    render() {
        return (
            <footer>
                <div className="container">
                    <section className="address">
                        {
                            ADDRESS_DATA.map((addr, idx) =>
                                <div key={ `addr-${idx}`}
                                    className="address-entry">
                                    <h2>{ addr.title }</h2>
                                    <div>{ addr.address }</div>
                                </div>
                            )
                        }
                        
                    </section>
                </div>
                <div className="footer-info">
                    <div className="grid">
                        <div className="filler"></div>
                        <div className="logo" ref={ this.logoContainer }>
                            { /*
                            <LogoSvg />
                            <img src={ LogoSvg } alt="Logo TV TEM" />
                            */ }
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/tvtem/">
                            <LogoFacebook />
                            </a>
                            <a href="https://instagram.com/tvtemoficial/">
                            <LogoInstagram />
                            </a>
                        </div>
                        <div className="filler"></div>

                        <div className="default">
                            <Link to="/fale-conosco">Fale conosco</Link>
                        </div>
                        <div className="default">
                            <a href="https://negocios.tvtem.com/portal/politica-privacidade/">Política de Privacidade</a>
                        </div>
                        <div className="default">
                            <a href="https://negocios.tvtem.com/portal/politica-de-cookies/">Cookies</a>
                        </div>
                        <div className="default">
                            <a href="https://negocios.tvtem.com/portal/termos-de-uso/">Termos de uso</a>
                        </div>

                        <div className="copyright">© tvtem2020. Todos os direitos reservados.</div>
                    </div>
                </div>
            </footer>
        );
    }
}

export {
    AppFooter,
};
