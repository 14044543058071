import React, { Component } from "react";

class PageSection extends Component {
    render() {
        let style = {};
        if (this.props["background-color"]) {
            style.backgroundColor = this.props["background-color"];
        }

        let background = null;
        if (this.props["background"]) {
            let bgStyle = {
                backgroundImage: `url(${ this.props["background"] })`,
            };
            background = <div
                data-background
                className="background"
                style={ bgStyle }></div>;
        }

        let props = {};
        if (this.props.id)
            props.id = this.props.id;

        return (
        <div className="page-section" style={ style } {...props}>
            { background }
            <div data-content>
                { this.props.children }
            </div>
        </div>
        );
    }
}

export {
    PageSection,
};
