import React, { Fragment, Component } from "react";

import "./hero-simple.scss";

const renderHeroContent = context =>
    <Fragment>
        <h1 data-animated>{ context.title }</h1>
        <div data-animated className="text">{ context.text }</div>
    </Fragment>;

const HeroContext = React.createContext(null);
class HeroContent extends Component {
    static contextType = HeroContext;

    render() {
        return renderHeroContent(this.context || {});
    }
}

const isHeroContent = c => {
    return !!(c.type && c.type.name === "HeroContent");
}

const HeroSimple = props => {
    let content = null;
    let heroContentMode = !!props["content-mode"];

    if (props.children && !heroContentMode) {
        if (Array.isArray(props.children)) {
            props.children.forEach(c => {
                if (isHeroContent(c))
                    heroContentMode = true;
            });
        }
        else if (isHeroContent(props.children))
            heroContentMode = true;
    }

    if (heroContentMode) {
        let context = {
            title: props.title,
            text: props.text,
        };

        content =
        <HeroContext.Provider value={ context }>
            { props.children }
        </HeroContext.Provider>;
    }

    if (!content)
        content = renderHeroContent(props);

    return (
        <div className={ `hero-simple ${ props.classes }` }>
            { content }
            { heroContentMode ? null : props.children }
        </div>
    );
}

export {
    HeroSimple,
    HeroContent,
};
