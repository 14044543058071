import React, { Component } from "react";
import { Link } from "react-router-dom";

import { registerEvent, unregisterEvent } from "./util";

import { ReactComponent as LogoSvg } from "../logo-tvtem.svg";

class AppHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login_visible: false,
            header_fixed: false,
        };

        this.documentClick = this.documentClick.bind(this);
        this.enqueueRender = this.enqueueRender.bind(this);
        this.relativeParent = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("click", this.documentClick);
        window.addEventListener("resize", this.enqueueRender);
        registerEvent("onreactroutechanged", this.enqueueRender);

        if (document.querySelector(".header-fixed")) {
            this.setState({ header_fixed: true });
        }
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.documentClick);
        window.removeEventListener("resize", this.enqueueRender);
        unregisterEvent("onreactroutechanged", this.enqueueRender);
    }

    enqueueRender() {
        if (this._renderQueued) return;

        window.requestAnimationFrame(() => {
            this._renderQueued = false;
            this.forceUpdate();
        });
        this._renderQueued = true;
    }

    documentClick(e) {
        let target = e.target;
        if (!this.state.login_visible || !target) return;

        let parent = target.closest("#login-container");
        if (parent) return;

        this.setState({
            login_visible: false,
        });
    }

    showLoginForm(e) {
        e.preventDefault();

        this.setState({
            login_visible: true,
        });
    }

    menuClick(e) {
        e.preventDefault();

        let tgt = e.target;
        if (tgt.tagName === "A") {
            let re = /(#.+)$/;
            let m = tgt.href.match(re);

            if (m) {
                const doScroll = () => {
                    let scrollTgt = document.querySelector(m[1]);
                    if (scrollTgt && window._scrollTo) {
                        window._scrollTo(scrollTgt.offsetTop);
                    }
                };

                let location = window.location.pathname;
                if (location !== "/") {
                    window.location.href = "/" + m[0];
                }
                else {
                    doScroll();
                }

            }
        }
    }

    render() {
        let style = {};
        let parent = this.relativeParent.current;
        if (this.state.header_fixed && parent) {
            style.width = `${ parent.offsetWidth }px`;
        }

        return (
            <div className="relative header-ref" ref={ this.relativeParent }>
            <header className="AppHeader" style={ style }>
                <div className="logo">
                    <Link to="/">
                        <LogoSvg />
                    </Link>
                </div>

                <div className="menu" onClick={ e => this.menuClick(e) }>
                    <a href="#regioes-tvtem">Regiões TV TEM</a>
                    <a href="#all-line">Comunicação All Line</a>
                    <a href="#oportunidades">Oportunidades</a>
                    <Link to="/fale-conosco">Fale Conosco</Link>
                </div>
                <div className="right">
                    <div id="login-container" className="relative">
                        <a href="#login"
                            onClick={ e => this.showLoginForm(e) }
                            >Área do Anunciante</a>
                        <AppLogin visible={ this.state.login_visible } />
                    </div>
                </div>
            </header>
            </div>
        );
    }
};

const AppLogin = props => {
    return (
    <div className={ `login-form ${ props.visible ? "visible" : "" }` }>
        <input type="text" placeholder="Usuário" />
        <input type="password" placeholder="Senha" />
        <button type="submit">Enviar</button>
    </div>
    );
}

export {
    AppHeader,
};
